<script lang="ts">
    import { inertia } from '@inertiajs/svelte';
    import toast from 'svelte-french-toast';
    import * as Card from '$lib/components/ui/card';
    import * as Tooltip from '$lib/components/ui/tooltip';
    import { Input } from '$lib/components/ui/input';
    import HammerIcon from '../../Icons/HammerIcon.svelte';
    import PostListIcon from '../../Icons/PostListIcon.svelte';
    import { Button } from '$lib/components/ui/button';
    import { Icon, Bell, Users } from 'svelte-hero-icons';
    import { createEventDispatcher, getContext } from 'svelte';
    import axios from 'axios';
    import { writable } from 'svelte/store';
    import { onMount } from 'svelte';

    export let item: any;

    const referralClaimContext = getContext('referralClaimContext');
    const { authUser } = referralClaimContext;
    const dispatch = createEventDispatcher();

    const showModal = writable(false);
    const isSubmitting = writable(false);

    let nextBidMinimumPrice = item.listing.auction?.current_bid
        ? minimumPriceCalculator(parseFloat(item.listing.auction.current_bid.amount))
        : 0.1;

    let latestPrice = item.listing.auction?.current_bid ? parseFloat(item.listing.auction.current_bid.amount) : 0;

    let modalData = {
        current_price: nextBidMinimumPrice.toFixed(2),
        user_id: item.user.id,
        bidder_user_id: authUser,
    };

    onMount(async () => {
        if (item.listing.auction?.current_bid) {
            initTheTimer(item.listing.auction.current_bid.bid_validity, item.user_id);
        }
    });

    function updateBidPrice(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        // Only update if the value is empty or a valid number (including decimal)
        if (value === '' || !isNaN(parseFloat(value))) {
            modalData.current_price = value;
        }
    }

    function handleClick() {
        dispatch('childClick', item.auction.id);
    }

    function getCurrentTimestamp() {
        return new Date().toISOString().slice(0, 19).replace('T', ' ');
    }

    function initTheTimer(bidValidity: string, id: string) {
        const currentTime = getCurrentTimestamp();
        initTimer(bidValidity, currentTime, id);
    }

    function handlePlaceBid() {
        modalData.current_price = nextBidMinimumPrice.toFixed(2);
        showModal.set(true);
    }

    async function handlePlaceBidSubmit() {
        if ($isSubmitting) return;

        const bidPrice = parseFloat(modalData.current_price) || 0;

        if (isNaN(bidPrice) || bidPrice < nextBidMinimumPrice) {
            toast.error(`Bid must be at least ${nextBidMinimumPrice}`);
            return;
        }

        isSubmitting.set(true);

        try {
            const response = await axios.post('/referral-claim/place-bid', {
                ...modalData,
                current_price: bidPrice,
            });

            latestPrice = parseFloat(response.data.current_bid_price);
            nextBidMinimumPrice = minimumPriceCalculator(latestPrice);
            modalData.current_price = nextBidMinimumPrice.toFixed(2);
            initTheTimer(response.data.validity, response.data.user_id);
            toast.success(response.data.message);
            showModal.set(false);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const errors = error.response.data.error || error.response.data.message;
                toast.error(errors || 'Failed to place bid');
            } else {
                toast.error('An unexpected error occurred');
            }
        } finally {
            isSubmitting.set(false);
        }
    }

    function minimumPriceCalculator(inputPrice: number | string): number {
        const price = parseFloat(inputPrice as string) || 0;

        if (price < 5) return parseFloat((price + 0.2).toFixed(2));
        if (price < 10) return parseFloat((price + 0.5).toFixed(2));
        return parseFloat((price + price / 10).toFixed(2));
    }

    function closeModal() {
        showModal.set(false);
    }
</script>

<Card.Root class="w-75 mb-3 bg-gray-100">
    <Card.Content class="flex items-center p-3">
        <div class="w-25 pr-3">
            <img src="{item.user.avatar}" alt="avatar" class="rounded-circle w-full" />
        </div>
        <div class="userDetail w-75 d-flex items-start">
            <div class="userBidDetails w-90 mx-3">
                <div>
                    <a href="{`/${user.username}`}" target="blank">
                        <h6 class="m-0 font-bold">{user.name} (22)</h6>
                    </a>
                    <p class="m-0">@twitter</p>

                    <Input
                        disabled
                        type="text"
                        value="{latestPrice.toFixed(2)}"
                        class="mb-2 mt-1 text-center text-xl font-bold text-black"
                    />

                    <div class="mb-2 flex justify-between">
                        <Tooltip.Root>
                            <Tooltip.Trigger>
                                <span class="flex items-center gap-1">
                                    <PostListIcon />
                                    {item.user.posts_count}
                                </span>
                            </Tooltip.Trigger>
                            <Tooltip.Content>Posts</Tooltip.Content>
                        </Tooltip.Root>
                        <Tooltip.Root>
                            <Tooltip.Trigger>
                                <span class="flex items-center gap-1">
                                    <Icon src="{Users}" size="20" />
                                    {item.user.subscribers_count}
                                </span>
                            </Tooltip.Trigger>
                            <Tooltip.Content>Subscribers</Tooltip.Content>
                        </Tooltip.Root>
                    </div>

                    <Button
                        variant="outline"
                        class="w-full bg-gradient-to-r from-indigo-500 to-purple-600 text-white hover:from-indigo-600 hover:to-purple-700"
                        size="sm"
                        on:click="{handlePlaceBid}"
                    >
                        Place Bid
                    </Button>
                </div>
                <div class="w-1/3">
                    <div class="mb-2 rounded-lg bg-gray-200 p-3">
                        <div class="mb-2 flex justify-between">
                            <HammerIcon />
                            <span class="w-2/3 rounded-md bg-white px-2 text-center font-semibold">
                                <span id="{`timer_${item.user_id}`}">00:00:00</span>
                            </span>
                        </div>
                        <div class="mb-2 rounded-md bg-white px-2 text-center">
                            {item.auction?.listing.auction.unique_bidders_count || 0} bidders
                        </div>
                        <div class="rounded-md bg-white px-2 text-center">
                            {item.auction?.listing.auction.bids_count || 0} bids
                        </div>
                    </div>
                    <div class="flex gap-2">
                        <a
                            use:inertia
                            href="{`/markets/my/auction/${item.id}`}"
                            class="flex-1 rounded-md bg-gradient-to-r from-indigo-500 to-purple-600 py-2 text-center text-white no-underline hover:from-indigo-600 hover:to-purple-700"
                        >
                            Profile
                        </a>
                        <Button variant="outline" size="sm" on:click="{handleClick}">
                            <Icon src="{Bell}" size="20" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </Card.Content>
</Card.Root>

{#if $showModal}
    <div class="fixed inset-0 z-50 flex items-center justify-center bg-black/60" on:click="{closeModal}">
        <div class="w-full max-w-md rounded-xl bg-white p-6 shadow-2xl" on:click|stopPropagation>
            <h2 class="mb-4 text-center text-2xl font-bold text-gray-800">Place Your Bid</h2>
            <p class="mb-4 text-center text-gray-600">
                Minimum Bid: <span class="font-semibold text-indigo-600">{nextBidMinimumPrice.toFixed(2)}</span>
            </p>

            <input
                type="text"
                inputmode="decimal"
                pattern="[0-9]*\.?[0-9]*"
                class="w-full rounded-lg border border-gray-300 p-3 text-white outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Enter your bid price"
                on:input="{updateBidPrice}"
                bind:value="{modalData.current_price}"
                disabled="{$isSubmitting}"
                required
            />

            <div class="mt-6 flex gap-4">
                <Button
                    variant="outline"
                    class="w-full bg-indigo-600 text-white hover:bg-indigo-700"
                    size="sm"
                    on:click="{handlePlaceBidSubmit}"
                    disabled="{$isSubmitting}"
                >
                    {#if $isSubmitting}
                        Placing Bid...
                    {:else}
                        Place Bid
                    {/if}
                </Button>
                <Button
                    variant="outline"
                    class="w-full bg-gray-200 text-gray-700 hover:bg-gray-300"
                    size="sm"
                    on:click="{closeModal}"
                    disabled="{$isSubmitting}"
                >
                    Cancel
                </Button>
            </div>
        </div>
    </div>
{/if}

<style>
    .no-underline {
        text-decoration: none;
    }
</style>
